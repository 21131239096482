import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import NodeTypeDescription from "../../components/ZenNodes/NodeTypeDescription"
import NodeTypeHeader from "../../components/ZenNodes/NodeTypeHeader"
import NodeTypeTable from "../../components/ZenNodes/NodeTypeTable"
import SuperNodesIcon from "../../components/ZenNodes/SuperNodesIcon"
import ContentContainer from "../../templates/ContentContainer"
import ForgerNodesIcon from "../../components/ZenNodes/ForgerNodesIcon"

export enum NodeType {
    SecureNodes = `secureNodes`,
    SuperNodes = `superNodes`,
    ForgerNodes = `forgerNodes`,
}

const SecureSuperNodes = ({
    nodesCount,
    nodeType,
}: {
    nodesCount?: string
    nodeType: NodeType
}) => {
    const { formatMessage } = useIntl()

    const getIcon = () => {
        switch (nodeType) {
            case NodeType.SuperNodes:
                return <SuperNodesIcon />
            case NodeType.ForgerNodes:
                return <ForgerNodesIcon />
            default:
                return null
        }
    }
    return (
        <section className="text-white">
            <ContentContainer>
                <NodeTypeHeader
                    icon={getIcon()}
                    title={formatMessage({
                        id: `zennodes.${nodeType}.header.title`,
                    })}
                    distance="space-x-[50px]"
                />
                <div className="grid lg:grid-cols-2 mt-8">
                    <NodeTypeDescription
                        isForgerNode={nodeType === NodeType.ForgerNodes}
                        activeNodes={nodesCount}
                        activeNodesLabel={formatMessage({
                            id: `zennodes.${nodeType}.description.activeNodesLabel`,
                        })}
                        description={formatMessage(
                            {
                                id: `zennodes.${nodeType}.description.desc`,
                            },
                            {
                                br: <br />,
                                EONNETWORKLINK: (
                                    <a
                                        href="https://eon.horizen.io/"
                                        className="text-links"
                                    >
                                        EON Network
                                    </a>
                                ),
                            }
                        )}
                        linkLabel={formatMessage({
                            id: `zennodes.${nodeType}.description.linkLabel`,
                        })}
                        linkUrl={formatMessage({
                            id: `zennodes.${nodeType}.description.linkUrl`,
                        })}
                        ctaLabel={formatMessage({
                            id: `zennodes.${nodeType}.description.ctaLabel`,
                        })}
                        ctaLink={formatMessage({
                            id: `zennodes.${nodeType}.description.ctaLink`,
                        })}
                    />
                    <NodeTypeTable tableType={nodeType} />
                </div>
            </ContentContainer>
        </section>
    )
}

export default SecureSuperNodes
