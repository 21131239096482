import React from "react"
import SectionHeading from "../SectionHeading"

export type NodeTypeHeaderProps = {
    icon: React.ReactNode
    title: string
    distance?: string
}

const NodeTypeHeader = ({
    icon,
    title,
    distance = "space-x-[40px]",
}: NodeTypeHeaderProps) => {
    return (
        <div className={`flex ${distance}`}>
            {icon}
            <div>
                <SectionHeading>{title}</SectionHeading>
            </div>
        </div>
    )
}

export default NodeTypeHeader
