import React from "react"

const ZenNodesHeroImg = () => {
    return (
        <svg
            width="63"
            height="62"
            viewBox="0 0 63 62"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M62.0558 30.9989C62.0558 13.8786 48.1644 0 31.0286 0C22.4562 0 14.7082 3.47188 9.08789 9.07825L16.8713 16.8545L17.8376 17.8199C21.2151 14.4455 25.878 12.3641 31.0286 12.3641C41.3296 12.3641 49.6804 20.7073 49.6804 30.9989C49.6804 36.1447 47.5882 40.8034 44.2196 44.1778L45.1858 45.1432L52.9692 52.9195C58.5807 47.3132 62.0558 39.5634 62.0558 30.9989Z"
                fill="url(#paint0_radial_1890_62)"
            />
            <path
                d="M44.2204 44.1778C36.9335 51.4582 25.1254 51.4582 17.8385 44.1778C10.5515 36.8975 10.5515 25.1002 17.8385 17.8199L9.08876 9.07825C-3.02959 21.1855 -3.02959 40.8123 9.08876 52.9195C21.2071 65.0268 40.8518 65.0268 52.9613 52.9195L44.2204 44.1778Z"
                fill="white"
            />
            <path
                d="M31.0291 22.5849C26.375 22.5849 22.6074 26.3757 22.6074 31.0432C22.6074 35.3299 25.7899 38.8549 29.9033 39.4129V34.6568C28.3696 34.1697 27.2438 32.7526 27.2438 31.0432C27.2438 28.9441 28.937 27.2436 31.0291 27.2436C33.1212 27.2436 34.8144 28.9441 34.8144 31.0432C34.8144 32.7437 33.6886 34.1697 32.1549 34.6568V39.4129C36.2683 38.8549 39.4507 35.3299 39.4507 31.0432C39.4507 26.3757 35.6832 22.5849 31.0291 22.5849Z"
                fill="white"
            />
            <defs>
                <radialGradient
                    id="paint0_radial_1890_62"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(51.4259 47.9331) scale(51.4935 51.4465)"
                >
                    <stop offset="0.0986" stopColor="#5ABE86" />
                    <stop offset="0.1985" stopColor="#59BA8C" />
                    <stop offset="0.3818" stopColor="#52B39E" />
                    <stop offset="0.6278" stopColor="#45A8B9" />
                    <stop offset="0.886" stopColor="#2E9BD6" />
                </radialGradient>
            </defs>
        </svg>
    )
}

export default ZenNodesHeroImg
