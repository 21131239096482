import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Button from "../../components/Button"
import SectionHeading from "../../components/SectionHeading"
import ContentContainer from "../../templates/ContentContainer"

const SetZenNode = () => {
    const { formatMessage } = useIntl()
    return (
        <section>
            <ContentContainer>
                <div className="flex lg:justify-between lg:items-center flex-col lg:flex-row">
                    <div>
                        <SectionHeading>
                            {formatMessage({ id: "zennodes.setZenNode.title" })}
                        </SectionHeading>
                        <p className="mt-6 text-lg leading-6 text-horizen-content-grey">
                            {formatMessage({ id: "zennodes.setZenNode.desc" })}
                        </p>
                    </div>
                    <div className="h-[41px] mt-8 lg:mt-0">
                        <Button
                            label={formatMessage({
                                id: "zennodes.setZenNode.cta",
                            })}
                            href={formatMessage({
                                id: "zennodes.setZenNode.cta.link",
                            })}
                        />
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default SetZenNode
