import React from "react"
import Helmet from "react-helmet"

const ZenNodesHelmet = () => {
    return (
        <Helmet>
            <title>
                ZenNodes - Bringing the most robust and resilient blockchain
                infrastructure - Horizen
            </title>
            <meta
                property="og:title"
                content="ZenNodes - Bringing the most robust and resilient blockchain infrastructure - Horizen"
            />
            <meta
                name="twitter:title"
                content="ZenNodes - Bringing the most robust and resilient blockchain infrastructure - Horizen"
            />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="Bringing the most robust and resilient blockchain infrastructure."
            />
            <meta
                property="og:description"
                content="Bringing the most robust and resilient blockchain infrastructure."
            />
            <meta
                name="twitter:description"
                content="Bringing the most robust and resilient blockchain infrastructure."
            />
            <meta
                property="og:image"
                content="https://www.horizen.io/assets/images/meta/zennodes.jpg"
            />
            <meta
                name="twitter:image"
                content="https://www.horizen.io/assets/images/meta/zennodes.jpg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="keywords"
                content="zennodes, zen nodes, horizen nodes, zen node"
            />
            <link rel="canonical" href="https://www.horizen.io/zennodes/" />
            <meta
                property="og:url"
                content="https://www.horizen.io/zennodes/"
            />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />/
        </Helmet>
    )
}

export default ZenNodesHelmet
