import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { NodeType } from "../../sections/zennodes/SecureSuperNodes"
import NodeTableRow from "./NodeTableRow"

type NodeTypeTableProps = {
    tableType: NodeType
}

const getZenAmount = (tableType: NodeType) => {
    switch (tableType) {
        case NodeType.SecureNodes:
            return 42
            break
        case NodeType.SuperNodes:
            return 500
            break
        default:
            return 0
    }
}

const NodeTypeTable = ({ tableType }: NodeTypeTableProps) => {
    const ZEN_AMOUNT = getZenAmount(tableType)
    const { formatMessage } = useIntl()
    const renderTableRows = () => {
        const tableRows = []
        let index = 0
        while (
            formatMessage({
                id: `zennodes.${tableType}.tableRows.${index}.title`,
            }) !== `zennodes.${tableType}.tableRows.${index}.title`
        ) {
            tableRows.push(
                <NodeTableRow
                    key={index}
                    title={formatMessage({
                        id: `zennodes.${tableType}.tableRows.${index}.title`,
                    })}
                    desc={formatMessage(
                        {
                            id: `zennodes.${tableType}.tableRows.${index}.desc`,
                        },
                        {
                            zenAmount: <strong>{ZEN_AMOUNT} ZEN</strong>,
                        }
                    )}
                />
            )
            index += 1
        }
        return tableRows.map((tableRow) => tableRow)
    }

    return (
        <div className="mt-8 lg:mt-0">
            <span className="font-bold text-sm text-horizen-grey uppercase tracking-[0.2em] leading-7">
                {formatMessage({ id: `zennodes.${tableType}.tableHeader` })}
            </span>
            {renderTableRows()}
            <a
                href={formatMessage({ id: `zennodes.${tableType}.linkUrl` })}
                className="text-links block mt-6"
                target="_blank"
                rel="noopener noreferrer"
            >
                [ {formatMessage({ id: `zennodes.${tableType}.linkLabel` })} ]
            </a>
        </div>
    )
}

export default NodeTypeTable
