import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import NodeTypeHeader from "../../components/ZenNodes/NodeTypeHeader"
import RegularNodeIcon from "../../components/ZenNodes/RegularNodeIcon"
import ContentContainer from "../../templates/ContentContainer"

const RegularFullNodes = () => {
    const { formatMessage } = useIntl()

    const renderListItems = () => {
        const listItems = []
        let index = 0
        while (
            formatMessage({
                id: `zennodes.regularNodes.listItems.${index}`,
            }) !== `zennodes.regularNodes.listItems.${index}`
        ) {
            listItems.push(
                <li key={index}>
                    {formatMessage({
                        id: `zennodes.regularNodes.listItems.${index}`,
                    })}
                </li>
            )
            index += 1
        }
        return listItems.map((listItem) => listItem)
    }

    return (
        <section className="text-white">
            <ContentContainer>
                <NodeTypeHeader
                    icon={<RegularNodeIcon />}
                    title={formatMessage({
                        id: "zennodes.regularNodes.title",
                    })}
                    subtitle={formatMessage({
                        id: "zennodes.regularNodes.subtitle",
                    })}
                />
                <div className="pt-9 grid lg:grid-cols-2">
                    <div className="flex items-center font-bold text-[22px] leading-7 text-horizen-content-grey">
                        {formatMessage({
                            id: "zennodes.regularNodes.notIncentivized",
                        })}
                    </div>
                    <div>
                        <span className="text-[22px] font-bold leading-7">
                            {formatMessage({
                                id: "zennodes.regularNodes.listTitle",
                            })}
                        </span>
                        <ul className="list-disc list-inside text-horizen-content-grey leading-6 ml-3">
                            {renderListItems()}
                        </ul>
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default RegularFullNodes
