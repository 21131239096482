import React from "react"

type NodeTableRowProps = {
    title: string | React.ReactNodeArray
    desc: string | React.ReactNode
}

const NodeTableRow = ({ title, desc }: NodeTableRowProps) => {
    return (
        <div className="flex items-center border-b min-h-[68px]">
            <div className="font-bold text-[22px] leading-7 min-w-[45%]">
                {title}
            </div>
            <div className="pl-2 lg:pl-0 leading-6 text-horizen-content-grey">
                {desc}
            </div>
        </div>
    )
}

export default NodeTableRow
