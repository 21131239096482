import React from "react"
import RegularFullNodes from "./RegularFullNodes"
import SecureSuperNodes, { NodeType } from "./SecureSuperNodes"

import bgImg from "../../assets/BackgroundImages/ZENNodes/Horizen_ZENNodes_main2.png"

const TypesOfNodes = ({ superNodesCount }: { superNodesCount: string }) => {
    return (
        <div
            className="pt-4"
            style={{
                backgroundImage: `url(${bgImg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "50% center",
            }}
        >
            <div>
                <SecureSuperNodes nodeType={NodeType.ForgerNodes} />
            </div>
            <div className="pt-24">
                <SecureSuperNodes
                    nodeType={NodeType.SuperNodes}
                    nodesCount={superNodesCount}
                />
            </div>
            <div className="pt-24">
                <RegularFullNodes />
            </div>
        </div>
    )
}

export default TypesOfNodes
