import React, { ReactNode } from "react"
import Button from "../Button"

type NodeTypeDescriptionProps = {
    isForgerNode?: boolean
    activeNodes: string
    activeNodesLabel: string
    description: string | ReactNode[]
    linkLabel: string
    linkUrl: string
    ctaLabel: string
    ctaLink: string
}

const NodeTypeDescription = ({
    isForgerNode = false,
    activeNodes,
    activeNodesLabel,
    description,
    linkLabel,
    linkUrl,
    ctaLabel,
    ctaLink,
}: NodeTypeDescriptionProps) => {
    return (
        <div className="pr-8">
            <div className="space-y-7">
                {!isForgerNode && (
                    <>
                        <div>
                            <span className="font-bold text-[41px] leading-[45px]">
                                {activeNodes}
                            </span>{" "}
                            <span className="text-lg ml-3">
                                {activeNodesLabel}
                            </span>
                        </div>
                        <hr className="w-[50px]" />
                    </>
                )}
                <p className="leading-6 text-horizen-content-grey">
                    {description}
                </p>
                <a
                    className="block text-links text-[22px] font-bold"
                    href={linkUrl}
                >
                    {linkLabel}
                </a>
                <Button
                    label={ctaLabel}
                    customClass="mt-12"
                    href={ctaLink}
                    openNewTab
                />
            </div>
        </div>
    )
}

export default NodeTypeDescription
