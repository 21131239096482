import React from "react"

const RegularNodeIcon = () => {
    return (
        <svg
            width="81"
            height="80"
            viewBox="0 0 81 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M80.0722 39.9986C80.0722 17.9079 62.1479 0 40.0371 0C28.976 0 18.9786 4.47984 11.7266 11.7139L21.7697 21.7478L23.0165 22.9935C27.3746 18.6393 33.3913 15.9537 40.0371 15.9537C53.3288 15.9537 64.1039 26.719 64.1039 39.9986C64.1039 46.6383 61.4044 52.6496 57.0578 57.0037L58.3046 58.2494L68.3477 68.2833C75.5883 61.0493 80.0722 51.0496 80.0722 39.9986Z"
                fill="url(#paint0_radial_1837_34)"
            />
            <path
                d="M57.0586 57.0037C47.6561 66.3976 32.4199 66.3976 23.0174 57.0037C13.6148 47.6097 13.6148 32.3874 23.0174 22.9935L11.7274 11.7139C-3.90914 27.3362 -3.90914 52.661 11.7274 68.2833C27.364 83.9056 52.712 83.9056 68.3371 68.2833L57.0586 57.0037Z"
                fill="white"
            />
            <path
                d="M40.0366 29.1418C34.0313 29.1418 29.1699 34.0331 29.1699 40.0557C29.1699 45.587 33.2764 50.1354 38.5839 50.8554V44.7184C36.605 44.0899 35.1523 42.2614 35.1523 40.0557C35.1523 37.3473 37.3371 35.1531 40.0366 35.1531C42.7361 35.1531 44.9209 37.3473 44.9209 40.0557C44.9209 42.2499 43.4682 44.0899 41.4893 44.7184V50.8554C46.7968 50.1354 50.9032 45.587 50.9032 40.0557C50.9032 34.0331 46.0418 29.1418 40.0366 29.1418Z"
                fill="white"
            />
            <defs>
                <radialGradient
                    id="paint0_radial_1837_34"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(66.3562 61.8492) scale(66.4432 66.3825)"
                >
                    <stop offset="0.0986" stopColor="#5ABE86" />
                    <stop offset="0.1985" stopColor="#59BA8C" />
                    <stop offset="0.3818" stopColor="#52B39E" />
                    <stop offset="0.6278" stopColor="#45A8B9" />
                    <stop offset="0.886" stopColor="#2E9BD6" />
                </radialGradient>
            </defs>
        </svg>
    )
}

export default RegularNodeIcon
