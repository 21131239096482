import axios from "axios"
import React, { useEffect, useState } from "react"
import ZenNodesHelmet from "../components/SEO/ZenNodesHelmet"
import SetZenNodeWithNewsletterForm from "../sections/zennodes/SetZenNodeWithNewsletterForm"
import TypesOfNodes from "../sections/zennodes/TypesOfNodes"
import ZenNodesHero from "../sections/zennodes/ZenNodesHero"
import Layout from "../templates/Layout"

import numberWithCommas from "../utils/numberWithCommas"
import { URLProvider } from "../utils/URLProvider"

const ZenNodes = () => {
    const [superNodesCount, setSuperNodesCount] = useState(0)

    useEffect(() => {
        axios.get(URLProvider.URL_SUPERNODES_API).then((res) => {
            setSuperNodesCount(res.data.global.up)
        })
    }, [])

    return (
        <Layout>
            <ZenNodesHelmet />
            <div className="bg-horizen-hp-bg">
                <ZenNodesHero />
                <TypesOfNodes
                    superNodesCount={numberWithCommas(superNodesCount)}
                />
                <SetZenNodeWithNewsletterForm />
            </div>
        </Layout>
    )
}

export default ZenNodes
