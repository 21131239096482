import React from "react"
import NewsletterForm from "../../components/NewsletterForm"
import SetZenNode from "./SetZenNode"

import LatestUpdatesBackground from "../../assets/LatestUpdatesBackground.png"

const SetZenNodeWithNewsletterForm = () => {
    return (
        <div
            style={{
                backgroundImage: `url(${LatestUpdatesBackground})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "50% center",
            }}
        >
            <div className="pt-20 lg:pt-44">
                <SetZenNode />
            </div>
            <div className="pt-24 lg:pt-60">
                <NewsletterForm />
            </div>
        </div>
    )
}

export default SetZenNodeWithNewsletterForm
