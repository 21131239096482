import React from "react"

const SuperNodesIcon = () => {
    return (
        <svg
            width="98"
            height="70"
            viewBox="0 0 98 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M48.6545 70L97.31 21.4278L76.1351 0H21.1738L29.1047 13.6014H68.2042L78.0618 22.7986L48.6545 52.1597V70Z"
                fill="url(#paint0_radial_1537_315)"
            />
            <path
                d="M19.2481 22.7986L29.1057 13.6014L21.1749 0L0 21.4278L48.6555 70V66.5194V54.0556V52.1597L19.2481 22.7986Z"
                fill="white"
            />
            <path
                d="M48.6606 19.5897C43.5517 19.5897 39.416 23.7508 39.416 28.8744C39.416 33.58 42.9095 37.4494 47.4247 38.0619V32.8411C45.7412 32.3064 44.5054 30.7508 44.5054 28.8744C44.5054 26.5703 46.364 24.7036 48.6606 24.7036C50.9571 24.7036 52.8157 26.5703 52.8157 28.8744C52.8157 30.7411 51.5799 32.3064 49.8964 32.8411V38.0619C54.4116 37.4494 57.9051 33.58 57.9051 28.8744C57.9051 23.7508 53.7694 19.5897 48.6606 19.5897Z"
                fill="white"
            />
            <defs>
                <radialGradient
                    id="paint0_radial_1537_315"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(80.7883 -1.05668) scale(60.6126 60.5149)"
                >
                    <stop offset="0.0986" stopColor="#5ABE86" />
                    <stop offset="0.1985" stopColor="#59BA8C" />
                    <stop offset="0.3818" stopColor="#52B39E" />
                    <stop offset="0.6278" stopColor="#45A8B9" />
                    <stop offset="0.886" stopColor="#2E9BD6" />
                </radialGradient>
            </defs>
        </svg>
    )
}

export default SuperNodesIcon
