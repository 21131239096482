import React from "react"
import NormalPageHero from "../../components/NormalPageHero"
import * as styles from "../../styles/zennodes/zennodes.module.css"
import ZenNodesHeroImg from "../../components/ZenNodes/ZenNodesHeroImg"

const ZenNodesHero = () => {
    return (
        <section
            className={`pt-[9rem] md:pt-[calc(4rem+150px)] ${styles.zennodesHero}`}
        >
            <NormalPageHero
                img={<ZenNodesHeroImg />}
                titleKey="zennodes.hero.title"
                subtitleKey="zennodes.hero.p1"
            />
        </section>
    )
}

export default ZenNodesHero
